
// Converted Variables


// Custom Media Query Variables


/*

   CODE

*/

.pre {
  overflow-x: auto;
  overflow-y: hidden;
  overflow:   scroll;
}
