
// Converted Variables

$black: #000 !default;
$near-black: #111 !default;
$dark-gray:#333 !default;
$mid-gray:#555 !default;
$gray: #777 !default;
$silver: #999 !default;
$light-silver: #aaa !default;
$moon-gray: #ccc !default;
$light-gray: #eee !default;
$near-white: #f4f4f4 !default;
$white: #fff !default;

$transparent:transparent !default;

$black-90: rgba(0,0,0,.9) !default;
$black-80: rgba(0,0,0,.8) !default;
$black-70: rgba(0,0,0,.7) !default;
$black-60: rgba(0,0,0,.6) !default;
$black-50: rgba(0,0,0,.5) !default;
$black-40: rgba(0,0,0,.4) !default;
$black-30: rgba(0,0,0,.3) !default;
$black-20: rgba(0,0,0,.2) !default;
$black-10: rgba(0,0,0,.1) !default;
$black-05: rgba(0,0,0,.05) !default;
$black-025: rgba(0,0,0,.025) !default;
$black-0125: rgba(0,0,0,.0125) !default;

$white-90: rgba(255,255,255,.9) !default;
$white-80: rgba(255,255,255,.8) !default;
$white-70: rgba(255,255,255,.7) !default;
$white-60: rgba(255,255,255,.6) !default;
$white-50: rgba(255,255,255,.5) !default;
$white-40: rgba(255,255,255,.4) !default;
$white-30: rgba(255,255,255,.3) !default;
$white-20: rgba(255,255,255,.2) !default;
$white-10: rgba(255,255,255,.1) !default;
$white-05: rgba(255,255,255,.05) !default;
$white-025: rgba(255,255,255,.025) !default;
$white-0125: rgba(255,255,255,.0125) !default;

$dark-red:  #e7040f !default;
$red:  #ff4136 !default;
$light-red:  #ff725c !default;
$orange:  #ff6300 !default;
$gold:  #ffb700 !default;
$yellow:  #ffd700 !default;
$light-yellow:  #fbf1a9 !default;
$purple:  #5e2ca5 !default;
$light-purple:  #a463f2 !default;
$dark-pink:  #d5008f !default;
$hot-pink: #ff41b4 !default;
$pink:  #ff80cc !default;
$light-pink:  #ffa3d7 !default;
$dark-green:  #137752 !default;
$green:  #19a974 !default;
$light-green:  #9eebcf !default;
$navy:  #001b44 !default;
$dark-blue:  #00449e !default;
$blue:  #357edd !default;
$light-blue:  #96ccff !default;
$lightest-blue:  #cdecff !default;
$washed-blue:  #f6fffe !default;
$washed-green:  #e8fdf5 !default;
$washed-yellow:  #fffceb !default;
$washed-red:  #ffdfdf !default;


// Custom Media Query Variables


/*

   Tachyons
   COLOR VARIABLES

   Grayscale
   - Solids
   - Transparencies
   Colors

*/
