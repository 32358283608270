// Converted Variables

$body-color: #515151;
$body-bg: #fff;
$primary-color: #FFC843;
$secondary-color: #6C6C6C;
$grey-color: #393939;

$sans-serif: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial, sans-serif !default;
$serif: georgia, serif !default;
$code: consolas, monaco, monospace !default;
$font-size-headline: 6rem !default;
$font-size-subheadline: 5rem !default;
$font-size-1: 3rem !default;
$font-size-2: 2.25rem !default;
$font-size-3: 1.5rem !default;
$font-size-4: 1.25rem !default;
$font-size-5: 1rem !default;
$font-size-6: .875rem !default;
$font-size-7: .75rem !default;
$letter-spacing-tight: -.05em !default;
$letter-spacing-1: .1em !default;
$letter-spacing-2: .25em !default;
$line-height-solid: 1 !default;
$line-height-title: 1.25 !default;
$line-height-copy: 1.5 !default;
$measure: 30em !default;
$measure-narrow: 20em !default;
$measure-wide: 34em !default;
$spacing-none: 0 !default;
$spacing-extra-small: .25rem !default;
$spacing-small: .5rem !default;
$spacing-medium: 1rem !default;
$spacing-large: 2rem !default;
$spacing-extra-large: 4rem !default;
$spacing-extra-extra-large: 8rem !default;
$spacing-extra-extra-extra-large: 16rem !default;
$spacing-copy-separator: 1.5em !default;
$height-1: 1rem !default;
$height-2: 2rem !default;
$height-3: 4rem !default;
$height-4: 8rem !default;
$height-5: 16rem !default;
$width-1: 1rem !default;
$width-2: 2rem !default;
$width-3: 4rem !default;
$width-4: 8rem !default;
$width-5: 16rem !default;
$max-width-1: 1rem !default;
$max-width-2: 2rem !default;
$max-width-3: 4rem !default;
$max-width-4: 8rem !default;
$max-width-5: 16rem !default;
$max-width-6: 32rem !default;
$max-width-7: 48rem !default;
$max-width-8: 64rem !default;
$max-width-9: 96rem !default;
$border-radius-none: 0 !default;
$border-radius-1: .125rem !default;
$border-radius-2: .25rem !default;
$border-radius-3: .5rem !default;
$border-radius-4: 1rem !default;
$border-radius-circle: 100% !default;
$border-radius-pill: 9999px !default;
$border-width-none: 0 !default;
$border-width-1: .125rem !default;
$border-width-2: .25rem !default;
$border-width-3: .5rem !default;
$border-width-4: 1rem !default;
$border-width-5: 2rem !default;
$box-shadow-1: 0px 0px 4px 2px rgba( 0, 0, 0, 0.2 ) !default;
$box-shadow-2: 0px 0px 8px 2px rgba( 0, 0, 0, 0.2 ) !default;
$box-shadow-3: 2px 2px 4px 2px rgba( 0, 0, 0, 0.2 ) !default;
$box-shadow-4: 2px 2px 8px 0px rgba( 0, 0, 0, 0.2 ) !default;
$box-shadow-5: 4px 4px 8px 0px rgba( 0, 0, 0, 0.2 ) !default;
$black: #000 !default;
$near-black: #111 !default;
$dark-gray: #333 !default;
$mid-gray: #555 !default;
$gray: #777 !default;
$silver: #999 !default;
$light-silver: #aaa !default;
$moon-gray: #ccc !default;
$light-gray: #eee !default;
$near-white: #f4f4f4 !default;
$white: #fff !default;
$transparent: transparent !default;
$black-90: rgba(0,0,0,.9) !default;
$black-80: rgba(0,0,0,.8) !default;
$black-70: rgba(0,0,0,.7) !default;
$black-60: rgba(0,0,0,.6) !default;
$black-50: rgba(0,0,0,.5) !default;
$black-40: rgba(0,0,0,.4) !default;
$black-30: rgba(0,0,0,.3) !default;
$black-20: rgba(0,0,0,.2) !default;
$black-10: rgba(0,0,0,.1) !default;
$black-05: rgba(0,0,0,.05) !default;
$black-025: rgba(0,0,0,.025) !default;
$black-0125: rgba(0,0,0,.0125) !default;
$white-90: rgba(255,255,255,.9) !default;
$white-80: rgba(255,255,255,.8) !default;
$white-70: rgba(255,255,255,.7) !default;
$white-60: rgba(255,255,255,.6) !default;
$white-50: rgba(255,255,255,.5) !default;
$white-40: rgba(255,255,255,.4) !default;
$white-30: rgba(255,255,255,.3) !default;
$white-20: rgba(255,255,255,.2) !default;
$white-10: rgba(255,255,255,.1) !default;
$white-05: rgba(255,255,255,.05) !default;
$white-025: rgba(255,255,255,.025) !default;
$white-0125: rgba(255,255,255,.0125) !default;

$gold-000: #201908;
$gold-100: #372b0e;
$gold-200: #4f3e15;
$gold-300: #69521c;
$gold-400: #846823;
$gold-500: #a07e2a;
$gold-600: #bd9432;
$gold-700: #dbac3a;
$gold-800: #f9c442;
$gold-900: #ffe1ae;

$gray-000: #1a1a1a;
$gray-100: #2c2c2c;
$gray-200: #404040;
$gray-300: #565656;
$gray-400: #6c6c6c;
$gray-500: #828282;
$gray-600: #9a9a9a;
$gray-700: #b2b2b2;
$gray-800: #cbcbcb;
$gray-900: #e5e5e5;

// Custom Media Query Variables

$breakpoint-not-small: 'screen and (min-width: 30em)' !default;
$breakpoint-medium: 'screen and (min-width: 30em) and (max-width: 60em)' !default;
$breakpoint-large: 'screen and (min-width: 60em)' !default;

//font-family
body {
    font-family: $sans-serif;
  }
  
  h1,.h1,
  h2,.h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-family: $serif;
    font-weight: 100;
  }
  
  p {
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
  }
  

//_border-colors
.b--primary { border-color: $primary-color }
.b--secondary { border-color: $secondary-color; }
.b--customgrey { border-color: $grey-color; }

.b--primary:after { border-color: $primary-color }
.b--secondary:after { border-color: $secondary-color; }
.b--customgrey:after { border-color: $grey-color; }

//_skins
.primary { color: $primary-color }
.secondary { color: $secondary-color; }
.customgrey { color: $grey-color; }

//also using .light-silver

.bg-primary { background-color: $primary-color }
.bg-secondary { background-color: $secondary-color; }
.bg-customgrey { background-color: $grey-color; }

//skins-pseudo

.hover-primary:hover { color: $primary-color; }
.hover-primary:focus { color: $primary-color; }


html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.hidden { 
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

textarea {
  resize: none;
  overflow: auto;
}

button, .custom-btn {
  line-height: 1;
  box-shadow: 0 2px 0 $secondary-color;
  border: 2px solid transparent;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  border-radius: 0;
  width: 100%;
    margin-top: 0;
    margin-bottom: 22px;
    padding: 8px 10px 7px;
    font-size: $font-size-4;
    text-decoration: none;
}

.custom-textarea {
  font-size: 1rem;
  font-family: $sans-serif;
line-height: 1.25;
-webkit-box-sizing: border-box;
box-sizing: border-box;
display: block;
width: 100%;
min-height: 40px;
margin-bottom: 20px;
padding: 5px;
resize: vertical;
border: 2px solid $grey-color;
border-radius: 0;
-webkit-appearance: none;
}

.services-content img, .work-content img {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.services-content p, .work-content p, .services-content ul {
  @extend .lh-copy;
  @extend .sans-serif;
  @extend .f4;
  @extend .measure-wide;
  @extend .pb3;
}
