
// Converted Variables


// Custom Media Query Variables


/*

   LETTER SPACING
   Docs: http://tachyons.io/docs/typography/tracking/

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/

.tracked       { letter-spacing:  .1em; }
.tracked-tight { letter-spacing: -.05em; }
.tracked-mega  { letter-spacing:  .25em; }

@media #{$breakpoint-not-small} {
  .tracked-ns       { letter-spacing:  .1em; }
  .tracked-tight-ns { letter-spacing: -.05em; }
  .tracked-mega-ns  { letter-spacing:  .25em; }
}

@media #{$breakpoint-medium} {
  .tracked-m       { letter-spacing:  .1em; }
  .tracked-tight-m { letter-spacing: -.05em; }
  .tracked-mega-m  { letter-spacing:  .25em; }
}

@media #{$breakpoint-large} {
  .tracked-l       { letter-spacing:  .1em; }
  .tracked-tight-l { letter-spacing: -.05em; }
  .tracked-mega-l  { letter-spacing:  .25em; }
}
