
// Converted Variables


// Custom Media Query Variables


/*
 
  BOX SIZING

*/

html,
body,
div,
article,
section,
main,
footer,
header,
form,
fieldset,
legend,
pre,
code,
a,
h1,h2,h3,h4,h5,h6,
p,
ul,
ol,
li,
dl,
dt,
dd,
textarea,
table, 
td,
th,
tr,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="url"],
.border-box {
  box-sizing: border-box;
}

